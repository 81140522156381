.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.hdr {
    font-weight: bold;
}

